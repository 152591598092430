.FAQ__question {
    background-color: rgb(4, 180, 122);
    border: none;
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  
  .FAQ__question:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .FAQ__answer--hidden {
    display: none;
  }
 .title {
   background-color: bisque;
 }