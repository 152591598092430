body{
    background-color: white;
}

data {
    background-color: rgb(236, 139, 20);
}

.setRowsOption {
    background-color: antiquewhite;
}

.what{
    color: rgb(248, 22, 158);
    text-align: center;
}

 .content {
    text-align: center;
 }